@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    @apply font-sans;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
    display: flex;
}

.content {
    flex-grow: 1;
    padding: 20px;
}

.dot {
    transition: transform 0.2s ease-in-out;
  }

.button {
    background-color: #008f3e;
    color: white;
}

.loadingBorder {
    --tw-border-opacity: 1;
    border-color: #008f3e;
}
